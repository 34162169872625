<template>
  <div class="tenders">
    <div class="tenders__filter">
      <div class="filter__title">Filtres</div>
      <div class="filter__field">
        <div class="field__title">Type</div>
        <div class="field__options">
          <label class="field__label">
            <input type="checkbox" v-model="queryParams.type" value="ao" class="field__control" />
            Appel d'offres
          </label>
          <label class="field__label">
            <input type="checkbox" v-model="queryParams.type" value="ami" id="-id" class="field__control" />
            Appel à manifestation d'intérêt
          </label>
        </div>
      </div>
      <div class="filter__field">
        <div class="field__title">État</div>
        <div class="field__options">
          <label class="field__label">
            <input type="checkbox" v-model="queryParams.etat" value="VALIDE" class="field__control" /> En cours
            d'examination
          </label>
          <label class="field__label">
            <input type="checkbox" v-model="queryParams.etat" value="ATTRIBUE" class="field__control" /> Attribué
          </label>
          <label class="field__label">
            <input type="checkbox" v-model="queryParams.etat" value="CREE" class="field__control" /> Publié
          </label>
          <!--<label class='field__label'>
            <input type='checkbox' name='type' value=''  class='field__control'/> Ouvert
          </label>-->
          <label class="field__label">
            <input type="checkbox" v-model="queryParams.etat" value="FERME" class="field__control" /> Fermé
          </label>
        </div>
      </div>
      <!--<div class='filter__field'>
        <div class='field__title'>Domaines Métiers</div>
        <div class='field__options'>
          <input type='text' class='field__search__options' />
          <div class='field__label' v-for='domaineMetier in domainesMetiers' :key='domaineMetier.id'>
            <div class='field__control'>
              <input type='checkbox' name='type' :value='domaineMetier.id' />
            </div>
            <div class='field__text'>{{ domaineMetier.libelle }}</div>
          </div>
        </div>
      </div>-->
      <div class="filter__field">
        <div class="field__title">Natures de prestation</div>
        <div class="field__options">
          <div class="field__label" v-for="item in naturesPrestations" :key="item.id">
            <input type="checkbox" v-model="queryParams.naturesPrestations" :value="item.id" class="field__control" />
            {{ item.libelle }}
          </div>
        </div>
      </div>
      <div class="filter__field">
        <div class="field__title">Entreprises</div>
        <div class="field__options">
          <input type="text" class="field__search__options" v-model="q.company" />
          <div
            class="field__label"
            v-for="entreprise in donneursOffres.filter((o) => o.name.toLowerCase().includes(q.company.toLowerCase()))"
            :key="entreprise.id"
          >
            <input type="checkbox" v-model="queryParams.entreprises" :value="entreprise.id" class="field__control" />
            {{ entreprise.name }}
          </div>
        </div>
      </div>
      <br />
      <Button
        @click="onFilter"
        :loading="isLoadingAmi || isLoadingAo"
        :disabled="isLoadingAmi || isLoadingAo"
        :label="isLoadingAmi || isLoadingAo ? 'Chargement des données' : 'Appliquer le filtre'"
        style="width: 100%; height: 25px"
        class="p-text-center"
      />
      &nbsp;
      <Button
        @click="resetAll"
        :label="'Effacer les filtres'"
        style="width: 100%; height: 25px"
        class="p-text-center"
      />
    </div>
    <div class="tenders__list">
      <h3 style="text-align: center" v-show="isLoadingAmi || isLoadingAo">Chargement des données ...</h3>
      <div class="tenders-period" v-for="key in Object.keys(thread)" :key="key">
        <div class="tenders-period__line">
          <h3 class="tenders-period__icon">{{ key[0].toUpperCase() }}</h3>
        </div>
        <div class="tenders-period__content">
          <h3 class="tenders-period__title">{{ capitalize(key) }}</h3>
          <publication-iitem v-for="item in thread[key]" :key="item.id" :item="item" />
        </div>
      </div>
      <h3 v-show="Object.keys(thread).length === 0" style="text-align: center">Aucune publication disponible</h3>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PublicationIitem from '@/components/publication-iitem';
import DateFormatMixin from '@/mixins/date-format';
import { capitalize } from 'vue';

export default {
  mixins: [DateFormatMixin],
  components: {
    PublicationIitem,
  },
  data: function () {
    return {
      q: {
        company: '',
      },
      queryParams: {
        type: [],
        etat: [],
        entreprises: [],
        naturesPrestations: [],
      },
      meta: {
        ao: {
          VALIDE: 1,
          ATTRIBUE: 7,
          CREE: 6,
          FERME: 5,
        },
        ami: {
          VALIDE: 2,
          ATTRIBUE: 7,
          CREE: 0,
          FERME: 6,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      domainesMetiers: 'domainesMetiers/getAllEntities',
      donneursOffres: 'entreprises/getDonneursOffres',
      naturesPrestations: 'naturePrestation/getAllEntities',
      thread: 'projetsMarches/getThreadByDate',
      isLoadingAmi: 'ami/getIsLoading',
      isLoadingAo: 'appelsOffres/getIsLoading',
    }),
  },
  methods: {
    ...mapActions({
      fetchAllDomainesMetiers: 'domainesMetiers/fetchAll',
      fetchAllEntreprises: 'entreprises/fetchAll',
      fetchAllNaturesPrestations: 'naturePrestation/fetchAll',
      fetchAllAppelsOffres: 'appelsOffres/fetchThread',
      fetchAllAppelsManifestationInteret: 'ami/fetchThread',
    }),
    capitalize,
    mapEtat(entity, etats) {
      return etats.map((etat) => this.meta[entity][etat]).join(',');
    },
    onFilter() {
      let params = Object.keys(this.queryParams)
        .filter((k) => this.queryParams[k].length > 0)
        .reduce((h, k) => ({ ...h, [k]: this.queryParams[k] }), {});

      let aoParams = { ...params };
      let amiParams = { ...params };

      if (Object.keys(params).length === 0) {
        aoParams = { etat: this.mapEtat('ao', 'VALIDE') };
        amiParams = { etat: this.mapEtat('ami', 'VALIDE') };
      } else if (params['etat']) {
        aoParams['etat'] = this.mapEtat('ao', params['etat']);
        amiParams['etat'] = this.mapEtat('ami', params['etat']);
      } else if (!params['etat']) {
        aoParams['etat'] = '1,7,6,5';
        amiParams['etat'] = '2,7,0,6';
      }

      if (this.queryParams.type.includes('ao') && !this.queryParams.type.includes('ami')) {
        this.fetchAllAppelsOffres({ queryParams: aoParams });
        this.$store.commit('ami/setThread', { entities: { data: [] } });
      } else if (!this.queryParams.type.includes('ao') && this.queryParams.type.includes('ami')) {
        this.fetchAllAppelsManifestationInteret({ queryParams: amiParams });
        this.$store.commit('appelsOffres/setThread', { entities: { data: [] } });
      } else {
        this.fetchAllAppelsOffres({ queryParams: aoParams });
        this.fetchAllAppelsManifestationInteret({ queryParams: amiParams });
      }
    },
    resetAll() {
      this.queryParams = {
        type: [],
        etat: [],
        entreprises: [],
        naturesPrestations: [],
      };
    },
  },

  mounted() {
    this.fetchAllDomainesMetiers();
    this.fetchAllEntreprises({ queryParams: { sort: 'name,asc', limit: 300, rankIds: [0, 1, 2] } });
    this.fetchAllNaturesPrestations();
    this.fetchAllAppelsOffres({ queryParams: { etat: '1,7,6' } }); // TODO: 1 -> valide, 7 attribué.
    this.fetchAllAppelsManifestationInteret({ queryParams: { etat: '2,7,0' } }); // TODO: 2 -> valide, 7 attribué.
  },
};
</script>

<style>
.tenders__list {
  background-color: #fff;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  width: 100%;
  max-height: 100vh;
  overflow: auto;
}

.tenders-period__title {
  font-size: 18px;
  width: 100%;
}

.tenders-period__content {
  width: 100%;
}

.tenders-card__title {
  font-size: 14px;
}

.tenders-card {
  border-bottom: 1px solid #eee;
  padding: 20px 0;
}

.tenders-period__line {
  margin: 0 20px 10px 10px;
  border-left: 2px dashed #e7e7e7;
}

.tenders-period__icon {
  margin: 10px 0 0 -11px;
  border: 1px solid;
  background: #fff;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  line-height: 25px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
}

.tenders-period {
  display: flex;
  margin-top: 20px;
}

.tenders {
  font-size: 12px;
  display: flex;
}

.tenders__filter {
  background-color: #fff;
  width: 350px;
}

.filter__title {
  font-size: 14px;
  font-weight: bold;
  padding: 10px 5px;
}

.filter__field {
  border-top: 1px solid #e7e7e7;
}

.field__options {
  background-color: #fafafa;
}

.field__title {
  font-size: 13px;
  font-weight: bold;
  padding: 5px;
}

.field__options {
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow: scroll;
  padding: 10px 5px;
}

.field__control {
}

.field__label {
  display: flex;
  text-overflow: ellipsis;
}

.field__search__options {
  margin-bottom: 5px;
  border: 1px solid #e7e7e7;
  line-height: 20px;
}
.field__search__options:focus {
  border: 1px solid #e7e7e7;
}
</style>

import axios from 'axios';


const httpToHttps = (url) =>
  process.env.NODE_ENV === "development"
    ? url.replace("https", "http")
    : (url.includes("https") === true
      ? url
      : url.replace("http", "https"));


const FilesMixin = {
  data: () => ({
    extensions: {
      pdf: "pdf",
      zip: "zip",
      jpg: "jpg",
      jpeg: "jpg",
      png: "jpg",
      doc: "doc",
      docx: "doc",
      txt: "txt",
      xls: "xls",
      xlsx: "xls",
      ppt: "ppt",
      gif: "gif",
    }
  }),
  methods: {
    getIconFromFileName(fileName) {
      const ext = this.getExtension(fileName);
      return "assets/files-icons/png/" + this.extensions[ext.toLowerCase()] + ".png";
    },

    getExtension(fileName) {
      const data = fileName.split(".");
      return data.length > 0 ? data[data.length - 1] : null;
    },

    getFileNameFromUrl(fileName) {
      const data = fileName.split("/");
      const name = data[data.length - 1].replaceAll("%20", " ");
      return name;
    },

    async onDownload(event, url) {
      event.preventDefault();
      // console.log({ url: httpToHttps(url), env: process.env.NODE_ENV });
      await axios.get(httpToHttps(url), {
        responseType: "blob",
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }).then(res => {
        var FILE = window.URL.createObjectURL(new Blob([res.data]));
        var docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', this.getFileNameFromUrl(url));
        document.body.appendChild(docUrl);
        docUrl.click();
      }).catch(() => {
        // console.log(e);
      });
    }
  }
};

export default FilesMixin;
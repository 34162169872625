<template>
  <div class="tenders-card">
    <div style="display: flex">
      <div v-if="item.entrepriseLogo">
        <img width="125" :src="item.entrepriseLogo" :alt="item.entrepriseName" />
      </div>
      <div style="display: flex; flex-direction: column; margin-left: 25px; width: 100%">
        <div>
          {{ item.entrepriseName }}
          <Badge :value="getLabel()" style="float: right" severity="info" size="small"></Badge>
        </div>
        <div>
          {{ item.intitule }}
          <Badge
            :value="enumMap[item.etat]"
            style="float: right; margin-top: 5px"
            severity="success"
            size="small"
          ></Badge>
        </div>
        <div><b>Publié le :</b> {{ getFormattedDate(item?.datePublication, 'dddd, DD MMMM YYYY') }}</div>
        <div><b>Date limite :</b> {{ getFormattedDate(item?.dateLimite, 'dddd, DD MMMM YYYY') }}</div>
      </div>
    </div>
    <div v-show="isOpen">
      <br />
      <h4 class="tenders-card__title">Détails de {{ getLabel().toLowerCase() }}.</h4>
      <p>{{ item.details }}</p>

      <h4 class="tenders-card__title">Instruction de la soumission</h4>
      <p>{{ item.instructions }}</p>

      <h4 class="tenders-card__title">Pièces jointes</h4>
      <ul style="list-style: none; padding: 0">
        <li v-for="(attachment, index) in item.piecesJointes" :key="index">
          <a style="cursor: pointer" @click="onDownload($event, attachment.urlPath)"
            ><img :src="getIconFromFileName(attachment.name)" width="25" /> {{ getFileNameFromUrl(attachment.name) }}</a
          >
        </li>
      </ul>
    </div>
    <div>
      <a @click="toggleDescription" style="font-weight: bold; float: right; cursor: pointer">
        <span v-if="isOpen"><i class="pi pi-angle-up"></i> Voir moins</span>
        <span v-else><i class="pi pi-angle-down"></i> Voir plus</span>
      </a>
      <br />
    </div>
  </div>
</template>

<script>
import DateFormatMixin from '@/mixins/date-format';
import FilesMixin from '../mixins/file-view';

export default {
  mixins: [DateFormatMixin, FilesMixin],
  name: 'publication-iitem',
  props: ['item'],
  data: () => {
    return {
      isOpen: false,
      enumMap: {
        SOUMIS: 'Transmis',
        TRANSMIS: 'Transmis',
        VALIDE: "En cours d'examination",
        ATTRIBUE: 'Attribué',
        CREE: 'Publié',
        FERME: 'Fermé',
      },
    };
  },
  methods: {
    toggleDescription() {
      this.isOpen = !this.isOpen;
    },

    getLabel() {
      return this.item.type === 'AO' ? "Appel d'offre" : "Appel à manifestation d'intérêt";
    },
  },
};
</script>